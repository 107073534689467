<template>
  <div class="loading-container">
    <div class="icon-container">
      <font-awesome-icon spin class="text-orange text-center " size="7x" :icon="['fas', 'spinner']"  />
      <h1 class="text-orange text-2xl">{{message && message}}</h1>
    </div>

  </div>
</template>

<script>
export default {
  name: "LoadingCard",
  props:{
    message: {
      type: String,
      required: false,
      default: ''
    },
  }
}
</script>

<style scoped>
.loading-container {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  display: table;
  transition: opacity 0.3s ease;
}
.icon-container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>