<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl">Admin Access Logs</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <div class="grid grid-cols-1 mb-8">
        <div class="m-auto"  v-if="errorFetching || isLoading">
          <ErrorCard v-if="errorFetching" message="Admin Access Logs Not Available"/>
        </div>
        <div v-else>
          <div class=" w-full">
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                :totalRows="userAccessLogs.totalElements"
                :isLoading.sync="isLoading"
                max-height="750px"
                theme="polar-bear"
                :sort-options="{
                enabled: false,
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: this.serverParams.perPage,
                  perPageDropdownEnabled: false,
                  dropdownAllowAll: false,
                  setCurrentPage: userAccessLogs.number + 1,
                }"
                :rows="userAccessLogs.content"
                :columns="columns"
                styleClass="vgt-table bordered"
                row-style-class="leading-loose"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field !== 'details'" :class="[props.column.field === 'createDateTime' && 'whitespace-nowrap', 'text-md font-medium']">
                  {{props.formattedRow[props.column.field]}}
                </span>
                <span v-else class="flex justify-center">
                    <button @click="onDetailClick(props.row)">
                     <FontAwesomeIcon :icon="['far', 'plus']" class="text-orange" size="2xl" />
                  </button>
                </span>
              </template>
              <template slot="table-column" slot-scope="props">
                 <span :class="[props.column.type === 'number' ? 'justify-end' : 'justify-start', 'flex text-orange block p-0 text-lg']">
                {{props.column.label}}
                 </span>
              </template>
              <div slot="loadingContent">
                <LoadingCard/>
              </div>
              <div slot="emptystate">
                No Printer Logs to show
              </div>
            </vue-good-table>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import { VueGoodTable } from 'vue-good-table';
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import LocalLogFormatters from "../../mixin/LocalLogFormatters";


export default {
  name: "AdminAccessLogs",
  mixins: [LocalLogFormatters],
  components: { BasicNav, ErrorCard , PageTemplate, VueGoodTable, LoadingCard, FontAwesomeIcon},
  data(){
    return {
      errorFetching: false,
      isLoading: true,
      columns: [
        {
          label: 'Session ID',
          field: 'sessionId',
          sortable: false,
          type: String,
        },
        {
          label: 'Username',
          field: 'username',
          type: String,
          sortable: false,
        },
        {
          label: 'Login Date',
          field: 'loginDateTime',
          sortable: false,
          formatFn: this.formatLogDate,
          type: String,
        },
        {
          label: 'Logout Date',
          field: 'logoutDateTime',
          formatFn: this.formatLogLogoutDate,
          sortable: false,
          type: String,
        },
        {
          label: "Details",
          field: "details"
        }
      ],
      rows: [],
      serverParams: {
        sort: 'loginDateTime,desc',
        page: 0,
        perPage: 8
      }
    };
  },
  computed:{
    ...mapGetters('GeneralSettings', ['kioskDetails']),
    ...mapState("GeneralSettings", ["userAccessLogs"]),
  },
  methods: {
    ...mapActions('GeneralSettings', ['getUserAccessLogs']),
    updateParams(newProps) {
      this.serverParams = {...this.serverParams, ...newProps};
    },
    onPageChange(params) {
      this.updateParams({page: params.currentPage -1});
      this.loadItems();
    },
    onDetailClick(params) {
      this.$router.push({name: 'AdminAccessDetail', params: {sessionId: params.sessionId}});
    },
    async loadItems() {
      this.isLoading = true;
      try{
        await this.getUserAccessLogs(this.serverParams);
      } catch {
        this.errorFetching = true;
      }

      this.isLoading = false;
    }
  },
  mounted() {
    this.loadItems();
  }

}
</script>
<style>
.chevron.right::after {
  border-left-color: rgba(245, 120, 18, 1) !important;
}
.chevron.left::after {
  border-right-color: rgba(245, 120, 18, 1) !important;
}
</style>